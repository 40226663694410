export default {
  additionalEvents: "Additional Events",
  allArticles: "All articles",
  backToTheTop: "back to the top",
  becomeMember: "Become a Member",
  cancel: "Cancel",
  chooseMembership: "I Choose",
  congratulations: "Congratulations!",
  contactUs: "Contact Us",
  currentMembership: "Current Membership",
  discount: "OFF",
  expiration: "Expiration",
  formGenericError: "Something went wrong. Please try again.",
  getInTouch: "Get In Touch With Our Team",
  goBack: "go back",
  goToPayment: "Go to payment method",
  membershipType: "Membership Type",
  month: "month",
  next: "next",
  needHelp: "Need help?",
  noAccount: "No account yet?",
  perUser: "Per user",
  previous: "previous",
  rememberMe: "Remember me",
  readMore: "read more",
  returnHome: "Return To Homepage",
  requiredInput: "Fields marked with asterisk are required",
  saveChanges: "Save changes",
  signIn: "Log In",
  signInNow: "Log In Now",
  signOut: "Log Out",
  signUp: "Sign up",
  status: "Status",
  statusActive: "Active",
  statusNotActive: "Not active",
  subscriptionTierCardSubtitle: "Per user, billed monthly or annually.",
  year: "year",
  yearlySubscription: "Yearly Subscription",
  youAreAMember: "You are now an AFV Member!",

  event: {
    addToCalendar: "Add to Calendar",
    showMap: "Show Map",
  },

  newsletter: {
    error: "Something went wrong. Try again later",
    errorExists: "You are already subscribed",
    success: "Your email was added to the list",
  },

  payments: {
    paymentMethodTitle: "Payment Method",
    payNow: "Pay Now",
    crypto: "crypto",
    fiat: "fiat",
    monthly: "Monthly Payments",
    yearly: "Yearly Payments (You will save 20%)",

    nowPayments: "Now Payments",
    nowPaymentsDescription:
      "For crypto payments, utilize Now Payments for swift and secure transactions with digital currencies.",

    stripe: "Stripe",
    stripeDescription:
      "Opt for quick and secure transactions with our fiat processor, paying effortlessly with your credit card.",
  },

  formFields: {
    labels: {
      avatar: "Avatar",
      avatarDescription:
        "Update your avatar by clicking the image on the left side. 288x288 px size (or higher) recommended in PNG, WEBP or JPG format only.",
      email: "Email",
      country: "Country",
      idNumber: "ID number",
      password: "Account password",
      passwordSimple: "Password",
      currentPassword: "Current Password",
      newPassword: "New Password",
      newPasswordConfirmation: "Confirm New Password",
      processing:
        "I willingly and consciously consent to Finance Valley collecting and processing the personal data I provide for marketing and advertising purposes. I understand that this processing may involve the communication of my data to entities within the Finance Valley group. Additionally, I acknowledge that Finance Valley may create a user profile based on my data to tailor communications to my interests. I have the option to personalize my consent by establishing a Finance Valley account, and I understand that the specifics of this process are detailed in the Privacy Policy of Finance Valley.",
      terms:
        "I have reviewed and agreed to the Terms and Conditions as well as the Privacy Policy of Finance Valley.",
    },

    placeholders: {
      country: "Select your country",
      email: "Enter your e-mail address",
      idNumber: "Enter document number...",
      password: "Enter your account password",
      passwordSimple: "Enter your password",
    },

    enterprise: {
      documentId: "Company ID",
      title: "Company Details",
      labels: {
        companyName: "Company name",
        fullAddress: "Full Address",
      },
      placeholders: {
        address: "Address",
        companyName: "Enter your company name...",
        city: "City",
        postal_code: "Postal Code",
      },
    },

    individual: {
      documentId: "Your ID",
      title: "Personal Information",
      labels: {
        birthDate: "Birth Date",
        city: "City",
        fullName: "Full Name",
        gender: {
          female: "Female",
          label: "Gender",
          male: "Male",
          preferNotToSay: "Prefer Not to Say",
        },
        idType: {
          label: "Document type",
          id_card: "ID Card",
          passport: "Passport",
        },
        postalCode: "Postal Code",
      },

      placeholders: {
        birthDate: "Choose your birth date",
        city: "Enter your city",
        idType: "Choose Document Type",
        fullName: "Enter your full name",
        postalCode: "Enter your postal code",
      },
    },
  },

  signUpSteps: {
    chooseMembership: "Choose Membership",
    confirmation: "Confirmation",
    payment: "Payment",
    personalInfo: "Personal Info",
  },

  subscriptionType: {
    individual: "Individual Package",
    enterprise: "Enterprise Package",
  },

  userNavigation: {
    dashboard: {
      title: "Dashboard",
      description: "Some example description",
    },
    help: {
      title: "Help Center",
      description: "Some example description",
    },
    membership: {
      title: "My Membership",
      description: "Manage your membership",
    },
    settings: {
      title: "Account Settings",
      description: "Change your account settings",
    },
    personalDetails: {
      title: "Personal details",
    },
    security: {
      title: "Security",
    },
  },

  waitlist: {
    joinWaitlist: "Join Waitlist",
    error: "Something went wrong. Try again later",
    errorExists: "You are already subscribed",

    form: {
      title: "Join the waitlist for the AFV Membership",
      submit: "Join The Waitlist",

      labels: {
        email: "E-Mail Address",
        fullName: "Full Name",
        terms: "I agree to the Terms & Conditions",
      },

      placeholders: {
        email: "Type your email...",
        fullName: "Type your full name...",
      },
    },

    success: {
      title: "We’ve added you to our waiting list!",
      subtitle: "We will let you know when AFV Membership is ready.",
      submit: "Ok, Thanks!",
    },
  },

  pageNotFound: {
    title: "Page not found",
    subtitle: "Seems like you lost your way...",
    description:
      "No problem at all, we're here to help. Simply click the button to go back to the homepage.",
    button: "Back to Homepage",
  },
};

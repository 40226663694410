export default {
  additionalEvents: "Eventos adicionais",
  allArticles: "Todos os artigos",
  backToTheTop: "voltar ao topo",
  becomeMember: "Tornar-se membro",
  cancel: "Cancelar",
  chooseMembership: "I Escolha",
  congratulations: "Parabéns!",
  currentMembership: "Membros atuais",
  discount: "OFF",
  expiration: "Expiração",
  formGenericError: "Algo correu mal. Por favor, tente novamente.",
  goBack: "voltar atrás",
  goToPayment: "Ir para o método de pagamento",
  membershipType: "Tipo de associação",
  month: "mês",
  next: "próximo",
  needHelp: "Precisa de ajuda?",
  noAccount: "Ainda não tem conta?",
  paid: "Pago",
  perUser: "Por utilizador",
  previous: "anterior",
  rememberMe: "Lembrar-se de mim",
  readMore: "ler mais",
  returnHome: "Voltar à página inicial",
  requiredInput: "Os campos marcados com asterisco são obrigatórios",
  saveChanges: "Guardar alterações",
  signIn: "Iniciar sessão",
  signInNow: "Iniciar sessão agora",
  signOut: "Terminar sessão",
  status: "Estado",
  statusActive: "Ativo",
  statusNotActive: "Não ativo",
  subscriptionTierCardSubtitle:
    "Por utilizador, facturado mensalmente ou anualmente.",
  year: "ano",
  yearlySubscription: "Assinatura anual",
  youAreAMember: "É agora um membro da AFV!",

  event: {
    addToCalendar: "Adicionar ao calendário",
    showMap: "Mostrar mapa",
  },

  newsletter: {
    error: "Algo correu mal. Tente novamente mais tarde",
    errorExists: "Já está inscrito",
    success: "O seu e-mail foi adicionado à lista",
  },

  payments: {
    paymentMethodTitle: "Método de pagamento",
    payNow: "Pagar agora",
    crypto: "crypto",
    fiat: "fiat",
    monthly: "Pagamentos mensais",
    yearly: "Pagamentos anuais (Poupará 20%)",

    nowPayments: "Pagamentos atuais",
    nowPaymentsDescription:
      "Para pagamentos criptográficos, utilize o Now Payments para transações rápidas e seguras com moedas digitais",

    stripe: "Stripe",
    stripeDescription:
      "Opte por transacções rápidas e seguras com o nosso processador fiat, pagando sem esforço com o seu cartão de crédito.",
  },

  formFields: {
    labels: {
      avatar: "Avatar",
      avatarDescription:
        "Actualize o seu avatar clicando na imagem do lado esquerdo. Recomenda-se o tamanho de 288x288 px (ou superior) apenas no formato PNG, WEBP ou JPG.",
      email: "Email",
      country: "País",
      idNumber: "Número de identificação",
      password: "Palavra-passe da conta",
      passwordSimple: "Palavra-passe",
      currentPassword: "Palavra-passe atual",
      newPassword: "Nova palavra-passe",
      newPasswordConfirmation: "Confirmar nova palavra-passe",
      processing:
        "Consinto, de livre vontade e conscientemente, que a Finance Valley recolha e processe os dados pessoais que forneço para fins de marketing e publicidade. Compreendo que este processamento pode envolver a comunicação dos meus dados a entidades do grupo Finance Valley. Além disso, reconheço que a Finance Valley pode criar um perfil de utilizador com base nos meus dados para adaptar as comunicações aos meus interesses. Tenho a opção de personalizar o meu consentimento, criando uma conta Finance Valley, e compreendo que as especificidades deste processo estão detalhadas na Política de Privacidade da Finance Valley.",
      terms:
        "Revi e concordei com os Termos e Condições, bem como com a Política de Privacidade da Finance Valley.",
    },

    placeholders: {
      country: "Seleccione o seu país",
      email: "Introduza o seu endereço de correio eletrónico...",
      idNumber: "Introduza o número do documento...",
      password: "Introduza a password da sua conta",
      passwordSimple: "Introduza a sua palavra-passe",
    },

    enterprise: {
      documentId: "ID da empresa",
      title: "Detalhes da empresa",
      labels: {
        companyName: "Nome da empresa",
        fullAddress: "Endereço completo",
      },
      placeholders: {
        address: "Endereço",
        companyName: "Introduza o nome da sua empresa...",
        city: "Cidade",
        postal_code: "Código postal",
      },
    },

    individual: {
      documentId: "O seu ID",
      title: "Informações pessoais",
      labels: {
        birthDate: "Data de nascimento",
        city: "Cidade",
        fullName: "Nome completo",
        gender: {
          female: "Feminino",
          label: "Género",
          male: "Masculino",
          preferNotToSay: "Prefere não dizer",
        },
        idType: {
          label: "Tipo de documento",
          id_card: "Bilhete de Identidade",
          passport: "Passaporte",
        },
        postalCode: "Código postal",
      },

      placeholders: {
        birthDate: "Escolha a sua data de nascimento",
        city: "Introduza a sua cidade",
        idType: "Escolha o tipo de documento",
        fullName: "Introduza o seu nome completo",
        postalCode: "Introduza o seu código postal",
      },
    },
  },

  signUpSteps: {
    chooseMembership: "Escolher afiliação",
    confirmation: "Confirmação",
    payment: "Pagamento",
    personalInfo: "Informação pessoal",
  },

  subscriptionType: {
    individual: "Pacote Individual",
    enterprise: "Pacote Enterprise",
  },

  userNavigation: {
    dashboard: {
      title: "Painel de controlo",
      description: "Alguns exemplos de descrição",
    },
    help: {
      title: "Centro de ajuda",
      description: "Alguns exemplos de descrição",
    },
    membership: {
      title: "A minha inscrição",
      description: "Gerir a sua adesão",
    },
    settings: {
      title: "Definições da conta",
      description: "Alterar as definições da sua conta",
    },
    personalDetails: {
      title: "Dados pessoais",
    },
    security: {
      title: "Segurança",
    },
  },

  waitlist: {
    joinWaitlist: "Aderir à lista de espera",
    error: "Algo correu mal. Tente novamente mais tarde",
    errorExists: "Já está inscrito",

    form: {
      title: "Inscrever-se na lista de espera para a adesão à AFV",
      submit: "Aderir à lista de espera",

      labels: {
        email: "Endereço eletrónico",
        fullName: "Nome completo",
        terms: "Aceito os Termos e Condições",
      },

      placeholders: {
        email: "Digite seu e-mail...",
        fullName: "Digite seu nome completo...",
      },
    },

    success: {
      title: "Adicionámo-lo à nossa lista de espera!",
      subtitle: "Informá-lo-emos quando a adesão à AFV estiver pronta.",
      submit: "Ok, obrigado!",
    },
  },

  pageNotFound: {
    title: "Página não encontrada",
    subtitle: "Parece que perdeste o teu caminho...",
    description:
      "Não há problema, estamos aqui para o ajudar. Basta clicar no botão para voltar à página inicial.",
    button: "Voltar à página inicial",
  },
};

import { defineStore } from "pinia";
import type { GlobalStore } from "~/modules/shared/ts/stores/GlobalStore";

export const useGlobalStore = defineStore("global", {
  state: () =>
    ({
      eventsListPage: undefined,
      footer: undefined,
      footerMenuPrimary: undefined,
      footerMenuSecondary: undefined,
      homepage: undefined,
      headerMenu: undefined,
      postsListPage: undefined,
    }) as GlobalStore,
});
